import style from '../components/TheFooter.module.css';
import flowerFooter from '../img/decor.png';
import phoneIcon from '../img/Phone.png';
import envelope from '../img/envelope.png';
import web from '../img/web.png';
import house from '../img/house.png';

export default function MySites() {
    return(
        <div className={style.container}>
            <div>
                <img src={flowerFooter} alt='' className={style.image}/>
            </div>
            <div className={style.contactContainer}>
                <div className={style.contactInformation}>
                    <h3>Justyna Miczek</h3>
                    <p className={style.role}>front-end web developer, problem-solver, coding and flowers lover </p>
                    <p className={style.letsTalk}>Need website? Or app? Call me, it's always good time to talk.</p>
                </div>
                <div className={style.contactContainer1}>
                    <div className={style.iconDesContainer}>
                        <img src={phoneIcon} alt='' className={style.phoneIcon}/>
                        <div>
                            <p className={style.biggerDesc}>Call me:</p>
                            <p className={style.smallerDesc}>+ 48 514 102 901</p>
                        </div>
                    </div>
                    <div className={style.iconDesContainer}>
                        <img src={envelope} alt='' className={style.phoneIcon}/>
                        <div>
                            <p className={style.biggerDesc}>Write to me:</p>
                            <p className={style.smallerDesc}>justynaskurzok@gmail.com</p>
                        </div>
                    </div>
                </div>
                <div className={style.contactContainer1}>
                    <div className={style.iconDesContainer}>
                        <img src={house} alt='' className={style.phoneIcon}/>
                        <div>
                            <p className={style.biggerDesc}>My website:</p>
                            <p className={style.smallerDesc}><a href='https://codewithblonde.com'>CodeWithBlonde.com</a></p>
                        </div>
                    </div>
                    <div className={style.iconDesContainer}>
                        <img src={web} alt='' className={style.phoneIcon}/>
                        <div>
                            <p className={style.biggerDesc}>Code &#40;GIT&#41;:</p>
                            <p className={style.smallerDesc}><a href='https://github.com/Justyna221222'>https://github.com/Justyna221222</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
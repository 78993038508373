import style from '../components/MyProjects.module.css';
import countingMaster from '../../src/img/CountingMaster.png';
import windowsCalculator from '../../src/img/WindowsCalculator.png';
import WeaherApp from '../../src/img/weatherApp.png';
import calculateCaloric from '../../src/img/calculateCaloricNeeds.png';
import addProduct from '../../src/img/AddProduct.png';
import naos from '../../src/img/Naos.png';
export default function MyProjects() {

    return(
        <div className={style.container}>
            <h2 className={style.header}>My projects</h2>
            <div className={style.projectsContainer}>
                <div className={style.projectCon}>
                    <a href="https://counting-master.netlify.app/" target="_blank" rel="noreferrer">
                        <div className={style.imageContainer}>
                            <img src={countingMaster} className={style.project} alt='counting master'/>
                            <div className={style.showPreview}><p>Click to see preview</p></div>
                        </div>
                    </a>
                    <div className={style.projectNameContainer}>
                        <p className={style.description}>Counting Master</p>
                        <p className={style.descriptionSmall}>App for kids to learn counting</p>
                        <p className={style.descriptionBigger}>Technologies:</p>
                        <p className={style.descriptionBigger}><strong>VUE js</strong>, HTML, CSS, JavaScript</p>
                        <p className={style.descriptionLink}><span>Code: </span><a href='https://github.com/Justyna221222/counting-master' target="_blank" rel="noreferrer">https://github.com/Justyna221222/counting-master</a> </p>
                        <a href="https://counting-master.netlify.app/" target="_blank" rel="noreferrer"><button>Click here to see live</button></a>
                    </div>
                </div>
                <div className={style.projectCon}>
                    <a href="https://windows-doors-calculator.netlify.app/" target="_blank" rel="noreferrer">
                        <div className={style.imageContainer}>
                            <img src={windowsCalculator} className={style.project} alt='counting master'/>
                            <div className={style.showPreview}><p>Click to see preview</p></div>
                        </div>
                    </a>
                    <div className={style.projectNameContainer}>
                        <p className={style.description}>Windows Calculator</p>
                        <p className={style.descriptionSmall}>Calculate window price based on given data</p>
                        <p className={style.descriptionBigger}>Technologies:</p>
                        <p className={style.descriptionBigger}><strong>VUE js, EmailJS</strong>, HTML, CSS, JavaScript</p>
                        <p className={style.descriptionLink}><span>Code: </span><a href='https://github.com/Justyna221222/window-calculator' target="_blank" rel="noreferrer">https://github.com/Justyna221222/window-calculator</a> </p>
                        <a href="https://windows-doors-calculator.netlify.app/" target="_blank" rel="noreferrer"><button>Click here to see live</button></a>
                    </div>
                </div>
                <div className={style.projectCon}>
                    <a href="https://weather-in-town.netlify.app/" target="_blank" rel="noreferrer">
                        <div className={style.imageContainer}>
                            <img src={WeaherApp} className={style.project} alt='weather app'/>
                            <div className={style.showPreview}><p>Click to see preview</p></div>
                        </div>
                    </a>
                    <div className={style.projectNameContainer}>
                        <p className={style.description}>Weather App</p>
                        <p className={style.descriptionSmall}>Check the weather</p>
                        <p className={style.descriptionBigger}>Technologies:</p>
                        <p className={style.descriptionBigger}><strong>API, React</strong>, HTML, CSS, JavaScript</p>
                        <p className={style.descriptionLink}><span>Code: </span><a href='https://github.com/Justyna221222/weather-app' target="_blank" rel="noreferrer">https://github.com/Justyna221222/weather-app</a> </p>
                        <a href="https://weather-in-town.netlify.app/" target="_blank" rel="noreferrer"><button>Click here to see live</button></a>
                    </div>
                </div>
            <div className={style.projectsContainer}>
                <div className={style.projectCon}>
                    <a href="https://kalkulator-kalorii.netlify.app/" target="_blank" rel="noreferrer">
                        <div className={style.imageContainer}>
                            <img src={calculateCaloric} className={style.project} alt='kalkulator kalorii'/>
                            <div className={style.showPreview}><p>Click to see preview</p></div>
                        </div>
                    </a>
                    <div className={style.projectNameContainer}>
                        <p className={style.description}>Caloric Needs</p>
                        <p className={style.descriptionSmall}>Calculate BMI and caloric needs</p>
                        <p className={style.descriptionBigger}>Technologies:</p>
                        <p className={style.descriptionBigger}><strong>React</strong>, HTML, CSS, JavaScript</p>
                        <p className={style.descriptionLink}><span>Code: </span><a href='https://github.com/Justyna221222/BMI-calculator' target="_blank" rel="noreferrer">https://github.com/Justyna221222/BMI-calculator</a> </p>
                        <a href="https://kalkulator-kalorii.netlify.app/" target="_blank" rel="noreferrer"><button>Click here to see live</button></a>
                    </div>
                </div>
                <div className={style.projectCon}>
                    <a href="https://addnewproduct.codewithblonde.com/" target="_blank" rel="noreferrer">
                        <div className={style.imageContainer}>
                            <img src={addProduct} className={style.project} alt='add product'/>
                            <div className={style.showPreview}><p>Click to see preview</p></div>
                        </div>
                    </a>
                    <div className={style.projectNameContainer}>
                        <p className={style.description}>Add Product</p>
                        <p className={style.descriptionSmall}>Add product to werehouse</p>
                        <p className={style.descriptionBigger}>Technologies:</p>
                        <p className={style.descriptionBigger}><strong>PHP, MySql</strong></p>
                        <p className={style.descriptionLink}><span>Code: </span><a href='https://github.com/Justyna221222/addProduct' target="_blank" rel="noreferrer">https://github.com/Justyna221222/addProduct</a> </p>
                        <a href="https://addnewproduct.codewithblonde.com/" target="_blank" rel="noreferrer"><button>Click here to see live</button></a>
                    </div>
                </div>
                <div className={style.projectCon}>
                    <a href="https://naosspa.pl/voucher/" target="_blank" rel="noreferrer">
                        <div className={style.imageContainer}>
                            <img src={naos} className={style.project} alt='Naos spa'/>
                            <div className={style.showPreview}><p>Click to see preview</p></div>
                        </div>
                    </a>
                    <div className={style.projectNameContainer}>
                        <p className={style.description}>Naos</p>
                        <p className={style.descriptionSmall}>Voucher purchase system. COMMERCIAL &#40;don't try&#41;</p>
                        <p className={style.descriptionBigger}>Technologies:</p>
                        <p className={style.descriptionBigger}><strong>pure JavaScript</strong>, SASS</p>
                        <p className={style.descriptionLink}><span>Code: private</span></p>
                        <a href="https://naosspa.pl/voucher/" target="_blank" rel="noreferrer"><button>Click here to see live</button></a>
                    </div>
                </div>
            </div>
            </div>
        </div>

    );
}
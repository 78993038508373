import style from './MySkills.module.css';
import gitSkill from '../img/gitHubSkill.png';
import htmlSkill from '../img/htmlSkill.png';
import cssSkill from '../img/cssSkill.png';
import jsSkill from '../img/jsSkill.png';
import vueSkill from '../img/vueJSSkill.png';
import reactSkill from '../img/reactSkill.png';
import dockerSkill from '../img/dockerSkill.png';
import phpSkill from '../img/phpMySql.png';
import mySql from '../img/mySql.png';
import bootstrapSkill from '../img/bootstrapSkill.png';
import boxBottom from '../img/bottom.png';


export default function AboutMe() {
return (
<div className={style.mySkillsContainer}>
<h2 className={style.header}>What I've learnt (and why):</h2>
    <div className={style.skills}>
    <div className={style.skillsPart}>
        <div className={style.skillContainer}>
        <img src={htmlSkill} alt='htmlSkill' className={style.skillLanguage} />
            <div className={style.showbox}>
                <p className={style.description1}>Basics. No comment needed</p>
                <img src={boxBottom} alt='' className={style.boxBottom} />
            </div>
        </div>
        <div className={style.skillContainer}>
        <img src={cssSkill} alt='cssSkill' className={style.skillLanguage} />
            <div className={style.showbox}>
                <p className={style.description1}>I've got also some experience with SASS</p>
                <img src={boxBottom} alt='' className={style.boxBottom} />
            </div>
        </div>
        <div className={style.skillContainer}>
        <img src={jsSkill} alt='jsSkill' className={style.skillLanguage} />
            <div className={style.showbox}>
                <p className={style.description1}>In pure JS I've made one commercial project.</p>
                <img src={boxBottom} alt='' className={style.boxBottom} />
            </div>
        </div>
    </div>
    <div className={style.skillsPart}>
        <div className={style.skillContainerVue}>
            <img src={vueSkill} alt='vueSkill' className={style.skillLanguage} />
            <div className={style.showbox}>
                <p className={style.description1}>My first love.
                 In Vue I've made one commercial and some non-commercial projects.</p>
                <img src={boxBottom} alt='' className={style.boxBottom} />
            </div>
        </div>
        <div className={style.skillContainer}>
            <img src={reactSkill} alt='reactSkills' className={style.skillLanguage} />
            <div className={style.showbox}>
                <p className={style.description1}>Popular and quite good. This site is also powered by React.</p>
                <img src={boxBottom} alt='' className={style.boxBottom} />
            </div>
        </div>
        <div className={style.skillContainerVue}>
            <img src={gitSkill} alt='gitSkill' className={style.skillLanguage} />
            <div className={style.showbox}>
                <p className={style.description1}>I store there my projects and used it with commercial projects.</p>
                <img src={boxBottom} alt='' className={style.boxBottom} />
            </div>
        </div>
        <div className={style.skillContainer}>
            <img src={dockerSkill} alt='docker skill' className={style.skillLanguage} />
            <div className={style.showbox}>
                <p className={style.description1}>it was boring to repeat: <br/>"On my computer it works"</p>
                <img src={boxBottom} alt='' className={style.boxBottom} />
            </div>
        </div>
    </div>
</div>

<h2 className={style.header}>What I'm currently learning:</h2>
<div className={style.skillsPart}>
        <div className={style.skillContainer}>
            <img src={phpSkill} alt='php skill' className={style.skillLanguage} />
            <div className={style.showbox}>
                <p className={style.description1}>I've already made one project &#40;see below&#41;.</p>
                <img src={boxBottom} alt='' className={style.boxBottom} />
            </div>
        </div>
        <div className={style.skillContainer}>
            <img src={mySql} alt='mySql skill' className={style.skillLanguage} />
            <div className={style.showbox}>
                <p className={style.description1}>I've used it in one of my project.</p>
                <img src={boxBottom} alt='' className={style.boxBottom} />
            </div>
        </div>
        <div className={style.skillContainer}>
            <img src={bootstrapSkill} alt='bootstrap Skill' className={style.skillLanguage} />
            <div className={style.showbox}>
                <p className={style.description1}>For me - great for tables and forms.</p>
                <img src={boxBottom} alt='' className={style.boxBottom} />
            </div>
        </div>
    </div>
</div>
);

}

import style from '../components/MySites.module.css';
import nwh from '../img/NWH.png';
import mocDrewna from '../img/mocDrewna.png';
import asgard from '../img/asgard.png';
import norweskieDomy from '../img/norweskieDomy.png';
import corkWindows from '../img/WindowsCork.png'
import flower1 from '../../src/img/flower1.png';
import flower2 from '../../src/img/flower2.png';
import flower3 from '../../src/img/flower3.png';
import flower4 from '../../src/img/flower4.png';
import flower5 from '../../src/img/flower5.png';
import flower6 from '../../src/img/flower6.png';
import flower7 from '../../src/img/flower7.png';
import flower8 from '../../src/img/flower8.png';
import flower9 from '../../src/img/flower9.png';
import flower10 from '../../src/img/flower10.png';
import flower11 from '../../src/img/flower11.png';
import flower12 from '../../src/img/flower12.png';

export default function MySites() {
    return (
        <div className={style.container}>
            <img src={flower1} className={style.flower1} alt=''/>
            <img src={flower2} className={style.flower2} alt=''/>
            <img src={flower3} className={style.flower3} alt=''/>
            <img src={flower4} className={style.flower4} alt=''/>
            <img src={flower5} className={style.flower5} alt=''/>
            <img src={flower6} className={style.flower6} alt=''/>
            <img src={flower7} className={style.flower7} alt=''/>
            <img src={flower8} className={style.flower8} alt=''/>
            <img src={flower9} className={style.flower9} alt=''/>
            <img src={flower10} className={style.flower10} alt=''/>
            <img src={flower11} className={style.flower11} alt=''/>
            <img src={flower12} className={style.flower12} alt=''/>
            <h2 className={style.header}>My internet Sites</h2>
            <p className={style.description}>
                What is my &#40;beside coding&#41; second hobby?
                Making Wordpress websites! <br/>I often put some CSS code to adjust plugins better.
                In next step I'll develop my skills in creating plugins.
            </p>
            <div className={style.sitesContainer}>
                <div className={style.siteContainer}>
                    <a href='https://nwhouse.pl' target="_blank" rel="noreferrer">
                        <div className={style.imgContainer}>
                            <img src={nwh} alt='Nordic Wooden House' className={style.image}/>
                            <div className={style.showPreview}><p>Click to see live</p></div>
                        </div>
                    </a>
                    <h3>Nordic Wooden House</h3>
                </div>
                <div className={style.siteContainer}>
                    <a href='https://mocdrewna.pl' target="_blank" rel="noreferrer">
                        <div className={style.imgContainer}>
                            <img src={mocDrewna} alt='Moc Drewna' className={style.image}/>
                            <div className={style.showPreview}><p>Click to see live</p></div>
                        </div>
                    </a>
                    <h3>Moc Drewna</h3>
                </div>
                <div className={style.siteContainer}>
                    <a href='https://www.asgardeeh.com/' target="_blank" rel="noreferrer">
                        <div className={style.imgContainer}>
                            <img src={asgard} alt='Nordic Wooden House' className={style.image}/>
                            <div className={style.showPreview}><p>Click to see live</p></div>
                        </div>
                    </a>
                    <h3>Asgard</h3>
                </div>
            </div>
            <div className={style.sitesContainer}>
                <div className={style.siteContainer}>
                    <a href='https://norweskiedomy.pl/' target="_blank" rel="noreferrer">
                        <div className={style.imgContainer}>
                            <img src={norweskieDomy} alt='Nordic Wooden House' className={style.image}/>
                            <div className={style.showPreview}><p>Click to see live</p></div>
                        </div>
                    </a>
                    <h3>Norweskie Domy</h3>
                </div>
                <div className={style.siteContainer}>
                    <a href='https://www.windowscork.ie/' target="_blank" rel="noreferrer">
                        <div className={style.imgContainer}>
                            <img src={corkWindows} alt='Nordic Wooden House' className={style.image}/>
                            <div className={style.showPreview}><p>Click to see live</p></div>
                        </div>
                    </a>
                    <h3>Cork Windows and Doors</h3>
                </div>
            </div>
        </div>
    );
}
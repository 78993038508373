//import flowercrown from '../img/fowercrown11.png';
import flowercrown1 from '../img/wianek333.png';
import html from '../img/html.png';
import css3 from '../img/css.png';
import js from '../img/js.png';
import vue from '../img/vuejs.png';
import react from '../img/react.png';
import docker from '../img/docker.png';
import git from '../img/GitHub-logo.png';
import style from './AboutMe.module.css';
export default function AboutMe() {

    return (
        <div className={style.container1}>
            <div className={style.aboutMe}>
                <p className={style.italic}>Hi, I wanted to tell you about hours spending in front of colorful code
                    on LapTop with red eyes and long que of mugs on the desk. </p>
                    <p><br/>But it&#8217;s a boring story,
                    so let&#8217;s go forward to these little moments when my code finally works
                    and every time that happend, there was a small celebration in my home office.<br/>
                    But what will be the future like? No bugs? No troubles?<br/>
                    Of course not. But let time pass.</p><br/><br/>
                    <p className={style.italic}>People keep asking me:  why coding? Why are you doing this?<br/>
                    My answer is: <br/> For fun.<br/><br/></p>
                    <p className={style.littleFont}>And for money.</p>
            </div>
            <div className={style.flowerCrownBox}>
                <img src={flowercrown1} alt='flower crown' className={style.insideFlowers}/>
                <img src={html} alt='html' className={style.showAndHideHtml}/>
                <img src={css3} alt='css3' className={style.showAndHideCss} />
                <img src={js} alt='js' className={style.showAndHideJs} />
                <img src={vue} alt='vue' className={style.showAndHideVue} />
                <img src={react} alt='vue' className={style.showAndHideReact} />
                <img src={docker} alt='vue' className={style.showAndHideDocker} />
                <img src={git} alt='vue' className={style.showAndHideGit} />
            </div>
        </div>
    );
}
import portrait from '../img/portret8.png';
import background from '../img/background2.png';
import style from './TheHeader.module.css';
import roses from '../img/rosesLeaves11.png';
import roses1 from '../img/rosesLeaves12.png';
import roses2 from '../img/rosesLeaves13.png';
export default function TheHeader() {


    return (
        <div className={style.headerContainer} style={{ backgroundImage:`url(${background})`, backgroundRepeat:"no-repeat", backgroundPosition: 'top right' }}>
        <img src={roses} alt='background roses' className={style.roses}/>
        <img src={roses1} alt='background roses' className={style.roses1}/>
        <img src={roses2} alt='background roses' className={style.roses2}/>
        <div>
            <div className={style.sentence}>
                <p>Every day I'm trying to make my <span className={style.bigger1}>code</span> clean and beautiful like <span className={style.bigger2}>flowers</span>.</p>
            </div>
            <div className={style.sentence1}>
                <p>Still trying.</p>
            </div>
            <div className={style.codeAndPortrait}>
                <div className={style.portrait}>
                    <img src={portrait} alt='Justyna Miczek' className={style.portraitImage}></img>
                </div>
                <div style={{color: "#F3D5D9"}} className={style.codeBlonde}>
                    <p>const <span className={style.bigCode}> Blonde</span> = &#123;</p>
                    <p className="m-4">name: <span className= {style.biggerCode}> 'Justyna Miczek',</span></p>
                    <p className="m-4">age: <span className={style.smallerCode}> 'no matter' </span>,</p>
                    <p className="m-4">proffesion: <span className={style.skil2}> 'Front-end developer' </span>,</p>
                    <p className="m-4">skills: &#91; <span className={style.skil1}> 'HTML' </span>, <span className={style.skil2}> 'CSS' </span>,<span className={style.skil1}> 'JavaScript'</span>,</p>
                    <p className={style.skills}><span className={style.skil2}>'VueJS'</span>, <span className={style.skil1}> 'React' </span>, <span className={style.skil2}> 'Bootstrap' </span> , <span className={style.skil1}> 'GIT' </span>,  <span className={style.skil2}>  'Docker' </span>&#93;,</p>
                    <p className="m-4">blonde: <span className={style.bigCode}> true,</span></p>
                    <p className="m-4">neverGiveUp:  <span className={style.smallerCode}> true </span></p>
                    <p>&#125;</p>
                </div>
            </div>
        </div>
        </div>
    );
}

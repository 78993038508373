import TheHeader from './components/TheHeader.jsx';
import AboutMe from './components/AboutMe.jsx';
import MySkills from './components/MySkills.jsx';
import MyProjects from './components/MyProjects.jsx';
import MySites from './components/MySites.jsx';
import TheFooter from './components/TheFooter.jsx';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <TheHeader></TheHeader>
        <AboutMe></AboutMe>
        <MySkills></MySkills>
        <MyProjects></MyProjects>
        <MySites></MySites>
        <TheFooter></TheFooter>
      </header>
    </div>
  );
}

export default App;
